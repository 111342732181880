<template>
<b-card v-if="users" no-body class="card-company-table">

    <b-table :items="users" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Start Time -->
        <template #cell(startDate)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.startDate | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- End Time -->
        <template #cell(endDate)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.endDate | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Status -->
        <template #cell(type)="data">
            <b-badge pill :variant="data.item.type=='Document'?'light-success':'light-danger'">
                {{data.item.type=='Document'?'Belge':'Test'}}
            </b-badge>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
            <b-button variant="flat-primary" @click="openDetails(data.item)" v-if="data.item.status=='SpecificUser'">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Detay</span>
            </b-button>
            <b-button variant="flat-warning" @click="editHomeWork(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Düzenle</span>
            </b-button>
            <b-button variant="flat-danger" @click="removeHomeWork(data.item)">
                <feather-icon icon="DeleteIcon" class="mr-50" />
                <span class="align-middle">Sil</span>
            </b-button>
        </template>
    </b-table>

    <!--Open Add HomeWork-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addHomeWorkPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Ev Ödevi Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Ödev Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addHomeWorkRequest.code" />
                </b-form-group>
                <b-form-group label="Ödev Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addHomeWorkRequest.name" />
                </b-form-group>
                <b-form-group label="Ödev Açıklaması" label-for="name">
                    <b-form-textarea name="firstName" id="name" type="text" v-model="addHomeWorkRequest.definition" />
                </b-form-group>
                <b-form-group label="Başlangıç Tarihi" label-for="name">
                    <flat-pickr v-model="addHomeWorkRequest.startDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                </b-form-group>
                <b-form-group label="Bitiş Tarihi" label-for="name">
                    <flat-pickr v-model="addHomeWorkRequest.endDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                </b-form-group>
                <b-form-group label="Tip" label-for="from">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(status) => status.key" value="key" label="name" :options="type" v-model="addHomeWorkRequest.type" />
                </b-form-group>
                <b-form-group label="Döküman Yükle" label-for="from" v-if="addHomeWorkRequest.type=='Document'">
                    <b-form-file v-model="addHomeWorkRequest.file" />
                </b-form-group>
                <b-form-group label="Test Seç" label-for="from" v-if="addHomeWorkRequest.type=='Test'">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(status) => status.id" value="id" label="title" :options="tests" v-model="addHomeWorkRequest.testId" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addHomeWork">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <!--Open Edit HomeWork-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editHomeWorkPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Ev Ödevi Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Ödev Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editHomeWorkRequest.code" />
                </b-form-group>
                <b-form-group label="Ödev Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editHomeWorkRequest.name" />
                </b-form-group>
                <b-form-group label="Ödev Açıklaması" label-for="name">
                    <b-form-textarea name="firstName" id="name" type="text" v-model="editHomeWorkRequest.definition" />
                </b-form-group>
                <b-form-group label="Başlangıç Tarihi" label-for="name">
                    <flat-pickr v-model="editHomeWorkRequest.startDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                </b-form-group>
                <b-form-group label="Bitiş Tarihi" label-for="name">
                    <flat-pickr v-model="editHomeWorkRequest.endDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                </b-form-group>
                <b-form-group label="Tip" label-for="from">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(status) => status.key" value="key" label="name" :options="type" v-model="editHomeWorkRequest.type" />
                </b-form-group>
                <b-form-group label="Döküman Yükle" label-for="from" v-if="editHomeWorkRequest.type=='Document'">
                    <b-form-file v-model="editHomeWorkRequest.file" />
                </b-form-group>
                <b-form-group label="Test Seç" label-for="from" v-if="editHomeWorkRequest.type=='Test'">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(status) => status.id" value="id" label="title" :options="tests" v-model="editHomeWorkRequest.testId" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateHomeWrok">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="openDetailsPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Ödev Detayları</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <!-- Body -->
            <div class="card-transaction px-2 py-1">
                <div class="transaction-item" @click="addStudent = true">
                    <b-media no-body>
                        <b-media-aside>
                            <b-avatar rounded size="42" variant="light-success">
                                <feather-icon size="18" icon="PlusIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="transaction-title">Yeni Öğrenci Ekle</h6>
                            <small>Ev Ödevini Atamak İstediğiniz öğrencileri ekleyin</small>
                        </b-media-body>
                    </b-media>
                    <div class="font-weight-bolder text-danger"></div>
                </div>
                <div class="transaction-item" v-for="student in details" :key="student.id">
                    <b-media no-body>
                        <b-media-aside>
                            <b-avatar rounded size="42" variant="light-primary">
                                <feather-icon size="18" icon="UserIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="transaction-title">
                                {{ student.firstName }} {{ student.lastName }}
                            </h6>
                            <small> {{student.email}} </small>
                        </b-media-body>
                    </b-media>
                    <b-button variant="danger" class="font-weight-bolder text-danger" @click="removeUser(student.id)">
                        ÇIKART
                    </b-button>
                </div>
            </div>
        </template>
    </b-sidebar>

    <b-modal title="Öğrenci Ekle" ok-title="Ekle" size="xl" ok-only cancel-variant="outline-secondary" v-model="addStudent" @ok="addUserHomeWork">
        <selected-student @AddUser="addUser" />
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </b-modal>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    BFormFile,
    BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SelectedStudent from './PageComponent/SelectedStudent.vue'
import flatPickr from 'vue-flatpickr-component'
export default {
    components: {
        SelectedStudent,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BFormDatepicker,
        flatPickr,
        BFormFile,
        BOverlay
    },
    data() {
        return {
            show: false,
            type: [{
                key: 'Document',
                name: "Döküman"
            }, {
                key: 'Test',
                name: "Sınav"
            }],
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Ödev Kodu' },
                { key: 'name', label: 'Ödev Adı' },
                { key: 'startDate', label: 'Ödev Başlama Tarihi' },
                { key: 'endDate', label: 'Ödev Bitiş Tarihi' },
                { key: 'type', label: 'Ödev Tipi' },
                { key: 'actions', label: 'İşlemler' },
            ],
            users: [],
            tests: [],
            addHomeWorkPanel: false,
            editHomeWorkPanel: false,
            addHomeWorkRequest: {
                videoGroupId: 0,
                code: '',
                name: '',
                definition: '',
                status: 1,
                type: null,
                testId: null,
                file: null,
                startDate: '',
                endDate: ''
            },
            editHomeWorkRequest: {
                homeWorkId: 0,
                code: '',
                name: '',
                definition: '',
                status: 1,
                type: null,
                testId: null,
                file: null,
                startDate: '',
                endDate: ''
            },
            selectedHomeWork: {},
            addStudent: false,
            openDetailsPanel: false,
            details: [],
            selectedUser: [],
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addHomeWorkPanel = true;
        });
    },
    created() {
        this.getData();
    },
    methods: {
        removeUser(ref) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: async confirm => {
                    if (confirm) {
                        await this.$http.delete("Teacher/DeleteHomeWorkDetails/" + this.selectedHomeWork.id + "/" + ref);
                        this.openDetails(this.selectedHomeWork);
                    }
                }
            });
        },
        addUserHomeWork(e) {
            this.show = true;
            e.preventDefault();
            var addReques = {
                "homeWorkId": this.selectedHomeWork.id,
                "users": []
            };
            this.selectedUser.forEach(user => {
                addReques.users.push(user.id);
            });

            this.$http.post("Teacher/AddHomeWorkDetails", addReques).then((data) => {
                    this.openDetails(this.selectedHomeWork);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kullanıcılar Eklendi",
                            variant: 'success'
                        },
                    });
                    this.show = false;
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                    this.show = false;
                });

        },
        addUser(users) {
            this.selectedUser = users;
        },
        async getData() {
            var users = await this.$http.get("Teacher/HomeWorks/" + this.$route.params.videoGroupId);
            this.users = users.data.data;

            var tests = await this.$http.get("Teacher/Test/" + this.$route.params.videoGroupId);
            this.tests = tests.data.data;

        },
        async removeHomeWork(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$http.delete("Teacher/DeleteHomeWork/" + data.id).then(() => {
                                this.getData();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'İşlem',
                                        icon: 'BellIcon',
                                        text: "İşlem Başarılı Ev Ödevi Silindi",
                                        variant: 'success'
                                    },
                                });
                            })
                            .catch((error) => {
                                //console.log(error.response.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Hata',
                                        icon: 'BellIcon',
                                        text: error.response.data.error.message,
                                        variant: 'danger'
                                    },
                                });
                            });
                    }
                }
            });
        },
        async openDetails(data) {
            this.selectedHomeWork = data;
            var details = await this.$http.get("Teacher/HomeWorkDetails/" + data.id);
            this.details = details.data.data;
            this.openDetailsPanel = true;

        },
        editHomeWork(data) {
            this.editHomeWorkRequest = {
                homeWorkId: data.id,
                code: data.code,
                name: data.name,
                definition: data.definition,
                type: data.type,
                status: data.status,
                startDate: data.startDate,
                endDate: data.endDate,
                isActive: data.isActive
            };
            if (data.type == 'Test') {
                this.editHomeWorkRequest.testId = data.test.id;
            }
            this.editHomeWorkPanel = true;
        },
        getFormattedDate(date) {
            var splitter = date.split('-');
            var day = splitter[0];
            var mnt = splitter[1];
            var yl = splitter[2];
            return mnt + '-' + day + '-' + yl;
        },
        addHomeWork() {
            this.addHomeWorkRequest.videoGroupId = this.$route.params.videoGroupId;
            if (this.addHomeWorkRequest.type == 0) this.addHomeWorkRequest.testId = '00000000-0000-0000-0000-000000000000';

            var request = new FormData();
            for (const property in this.addHomeWorkRequest) {
                var type = typeof this.addHomeWorkRequest[property];
                if (type == 'string') {
                    request.append(property, this.addHomeWorkRequest[property].toString());
                } else {
                    request.append(property, this.addHomeWorkRequest[property]);
                }
            }

            //request.set("startDate", this.getFormattedDate(this.addHomeWorkRequest.startDate));
            //request.set("endDate", this.getFormattedDate(this.addHomeWorkRequest.endDate));

            this.$http.post("Teacher/AddHomeWork", request).then((data) => {
                    this.getData();
                    this.addHomeWorkPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Ev Ödevi Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateHomeWrok() {
            if (this.editHomeWorkRequest.type == 0) this.editHomeWorkRequest.testId = '00000000-0000-0000-0000-000000000000';

            var request = new FormData();
            for (const property in this.editHomeWorkRequest) {
                var type = typeof this.editHomeWorkRequest[property];
                if (type == 'string') {
                    request.append(property, this.editHomeWorkRequest[property].toString());
                } else {
                    request.append(property, this.editHomeWorkRequest[property]);
                }
            }

            // request.set("startDate", this.getFormattedDate(this.editHomeWorkRequest.startDate));
            // request.set("endDate", this.getFormattedDate(this.editHomeWorkRequest.endDate));

            this.$http.put("Teacher/UpdateHomeWork", request).then((data) => {
                    this.getData();
                    this.editHomeWorkPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Ev Ödevi Düzenlendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
